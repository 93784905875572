/*
 * @Author: 张博洋
 * @Date: 2021-07-22 11:22:30
 * @LastEditTime: 2021-07-22 15:10:04
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/mixin/Cell.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import {
  Icon,
  Cell,
  SwipeCell,
  Tag,
  CellGroup
} from 'vant';

export default {
  components: (() => {
    let obj = {};
    obj[Tag.name] = Tag;
    obj[Icon.name] = Icon;
    obj[Cell.name] = Cell;
    obj[SwipeCell.name] = SwipeCell;
    obj[CellGroup.name] = CellGroup;
    return obj;
  })(),
}
