<!--
 * @Author: 张博洋
 * @Date: 2021-07-15 16:31:28
 * @LastEditTime: 2021-07-26 17:03:21
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/routes/index.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <!-- <div class="app-nav app-top-bar bg-white">
            <van-nav-bar :title="$root.title" @click-left="appNavLeftClick" />
        </div> -->
    <div class="flex1 over-y"
         ref="scrollBody">
      <!-- <aa-m></aa-m> -->
      <van-cell-group>
        <template v-for="(item,index) in routes">
          <van-cell :key='index'
                    is-link
                    :to='$._.merge({name:item.name},item.meta.route||{})'>
            <template #title
                      v-if='item.meta'>
              <span>{{item.meta.title}}</span>
              <!-- <span v-if='item.meta.erp'>({{item.meta.erp}})</span> -->
            </template>
            <template #icon>
              <div class="mt2 mr5 color-primary">
                <van-icon name="checked"
                          v-if='item.meta.done' />
                <van-icon name="play-circle"
                          class='color-price'
                          v-else-if='item.meta.edit' />
              </div>
            </template>
            <template #label>
              <span class="color-primaryLight">{{item.meta?item.meta.label:""}}</span>
            </template>
          </van-cell>
        </template>
      </van-cell-group>
    </div>
    <!-- <a-link to='https://yao.jd.com/activity/move/detail/249?VNK=06a896f5#showAllProduct_100000315'>dfdf</a-link> -->
  </div>
</template>

<script>
import vanCell from '@/mixin/Cell.js';

export default {
  provide () {
    return {
      APP1: this,
    }
  },
  mixins: [vanCell],
  computed: {
    routes () {
      console.log(this.$route);
      let r = this.$.extend(true, {}, this.$router.options);
      return r.routes[0].children
    }
  },
  data () {
    return {
      list: [1, 2, 3]
    }
  },
  created () {
    console.log(this.routes)
  },
  methods: {
    getData () {
      this.$axios({
        url: '/p/basedata/patient/list',
        data: {
          pin: this.$.userInfo.pin
        },
        elseData: {
          ajaxLoading: true,
        },
        _self: this
      }).then(res => {
        console.log(`示例请求回来的数据${res.d}`);
      })
    }
  },
}

</script>

<style lang="scss" scoped>
/deep/ {
  .van-cell__left-icon {
    color: color(primary);
  }
}
</style>